table.dictionary-table {
	margin: 1.5rem 0;
	width: 100%;

	@include brightTheme {
		display: flex;
		flex-flow: column;
		gap: 20px;
	}

	.caption {
		font-size: 13px;
		text-transform: uppercase;
	}

	.caption,
	.text {
		overflow-wrap: anywhere;
	}

	@include brightTheme {
		tr {
			display: flex;
			flex-flow: column;

			.caption {
				font-size: 12px;
				font-weight: bold;
			}
		}
	}

	@include notBrightThemeOrBlox {
		@include media('<640px') {
			tr {
				display: flex;
				flex-flow: column;
				margin: 1.5rem $card-padding-xs;

				&:first-child {
					margin-top: 0;
				}

				&:last-child {
					margin-bottom: 0;
				}

				.text {
					margin-top: 0.5rem;
				}
			}
		}

		@include media('>=640px') {
			tr:nth-child(odd) {
				background: rgba(255, 255, 255, 0.05);
			}

			.caption {
				min-width: 250px;
			}

			.caption,
			.text {
				padding: 0.5rem $card-padding-md;
			}

			.text {
				@include padding-inline-start(0);
			}
		}
	}
}
