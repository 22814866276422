@use 'sass:math';

.card {
	border-radius: $border-radius;
	color: $var-color-text-primary;

	:root.dark-theme & {
		background: $card-gradient;
	}

	:root.transparent-theme &,
	:root.admin-theme &,
	:root.light-theme & {
		@include elevation(4, $elevation-color, 0.5);
		background: white;
	}

	@include notBrightThemeOrBlox {
		position: relative;
		padding: $card-padding-xs $card-padding-xs 30px;
		min-height: 200px;
		min-width: 100%;

		@include media('>=phone-lg') {
			padding: $card-padding-sm $card-padding-sm 40px;
			min-height: 300px;
			min-width: 400px;
		}

		@include media('>=tablet') {
			padding: $card-padding-md $card-padding-md 40px;
		}
	}

	@include brightTheme {
		background: transparent;
	}

	&,
	input {
		transition: color $transition-props;
	}

	&.payment-card {
	}

	&.transaction-processing-card,
	&.psp-card {
		width: 100% !important;
	}

	&.psp-card,
	&.status-card {
		max-width: 600px;
		margin: 0 auto;
	}

	&.status-card {
		display: flex;
		flex-flow: column;
		justify-content: center;
		gap: 1rem;
		width: fit-content;

		@include notBrightThemeOrBlox {
			padding: 25px;

			@include media('>=phone-lg') {
				padding: 50px;
			}
		}

		@include brightTheme {
			gap: 1.5rem;
			width: 100%;
		}

		.header {
			display: flex;
			align-items: center;

			@include notBrightThemeOrBlox {
				gap: 1rem;

				@include media('>=phone-lg') {
					gap: 2rem;
				}
			}

			@include brightTheme {
				flex-flow: column;
				text-align: center;
				gap: 1rem;
			}

			.card-logo {
				@include notBrightThemeOrBlox {
					width: 50px;

					@include media('>=phone-lg') {
						width: 85px;
					}

					path {
						&:not(.stroked) {
							fill: currentColor;
						}

						&.stroked {
							stroke: currentColor;
						}
					}
				}
			}

			.heading {
				.title {
					margin-bottom: 0 !important;

					@include brightTheme {
						font-size: $var-h2-font-size;
						font-weight: $var-h2-font-weight;
						line-height: unset;
					}

					@include notBrightThemeOrBlox {
						text-transform: uppercase;
						@include font(16px, 22px, 700);

						@include media('>=phone-lg') {
							@include font(22px, 31px, 700);
						}
					}
				}
			}
		}

		.card-content {
			display: flex;
			flex-flow: column;
			justify-content: center;
			gap: 1rem;

			&:empty {
				display: none;
			}

			@include brightTheme {
				&:not(:empty) {
					margin-top: -1rem;
				}
			}

			.text,
			.message {
				@include brightTheme {
					font-size: $var-h3-font-size;
					font-weight: $var-h3-font-weight;
				}
			}
		}

		.subtext,
		.card-table {
			@include notBrightThemeOrBlox {
				@include fontsize(10px, 14px);

				@include media('>=phone-lg') {
					@include fontsize(12px, 16px);
				}
			}

			@include brightTheme {
				font-size: $bright-theme-mat-body-1-font-size;
			}
		}

		.card-table {
			color: currentColor;
			width: 100%;

			tbody {
				@include brightTheme {
					@include media('<phone-lg') {
						display: flex;
						flex-flow: column;
						gap: 10px;
					}
				}
			}

			tr {
				@include notBrightThemeOrBlox {
					@include media('<430px') {
						display: flex;
						flex-flow: column;
						margin-bottom: 0.5rem;
					}
				}

				@include brightTheme {
					@include media('<phone-lg') {
						display: flex;
						flex-flow: column;

						td {
							padding-top: 0;
							padding-bottom: 0;
						}
					}
				}
			}

			td {
				overflow-wrap: anywhere;

				@include notBrightThemeOrBlox {
					font-weight: 400;
					padding-top: 4px;
					padding-bottom: 4px;

					@include media('<430px') {
						padding: 0;
						margin-bottom: 0.2rem;
					}
				}

				@include brightTheme {
					padding-top: 7px;
					padding-bottom: 7px;
				}

				&:first-child {
					width: 120px;
					white-space: nowrap;
					@include padding-inline-end(1rem);

					@include notBrightThemeOrBlox {
						text-transform: uppercase;
					}
				}

				&:not(:first-child) {
					font-weight: 500;

					@include media('>=phone-lg') {
						text-align: right;
					}
				}
			}
		}

		.subtext {
			@include notBrightThemeOrBlox {
				@include media('<phone-lg') {
					margin-bottom: 1rem;
				}
			}

			@include brightTheme {
				text-align: center;
			}
		}

		.buttons {
			@include brightTheme {
				display: flex;
				gap: 1rem;

				&:empty {
					display: none;
				}

				> button {
					flex: 1;
				}
			}
		}
	}

	&.success-card {
		:root.dark-theme & {
			background: $success-gradient;
		}

		:root.transparent-theme &,
		:root.light-theme &,
		:root.admin-theme & {
			background: white;
			outline: 5px solid;
			outline-offset: -5px;
			color: $var-color-success;
		}
	}

	&.pending-card {
		:root.dark-theme & {
			background: $warning-gradient;
		}

		:root.transparent-theme &,
		:root.light-theme &,
		:root.admin-theme & {
			background: white;
			outline: 5px solid;
			outline-offset: -5px;
			color: $var-color-warning;
		}
	}

	&.error-card {
		:root.dark-theme & {
			background: $error-gradient;
		}

		:root.transparent-theme &,
		:root.light-theme &,
		:root.admin-theme & {
			background: white;
			outline: 5px solid;
			outline-offset: -5px;
			color: $var-color-error;
		}
	}

	.title {
		display: inline-block;

		@include notBrightThemeOrBlox {
			@include font(16px, 20px, 700);
			margin-bottom: 1rem;
		}

		@include brightTheme {
			font-size: $var-h1-font-size;
			font-weight: $var-h1-font-weight;
			line-height: unset;
		}
	}

	.card-content {
		display: flex;
		flex-flow: row wrap;

		.text {
			@include font(18px, 22px, 400);
		}

		&:empty,
		.message:empty {
			display: none;
		}
	}

	.card-float-button-position {
		z-index: $zindex-button;
		position: absolute;
		bottom: -(math.div($btn-height, 2));

		&.right {
			@include inset-inline-end($card-padding-xs);

			@include media('>=phone') {
				@include inset-inline-end($card-padding-sm);
			}

			@include media('>=tablet') {
				@include inset-inline-end($card-padding-md);
			}
		}

		&.left {
			@include inset-inline-start($card-padding-xs);

			@include media('>=phone') {
				@include inset-inline-start($card-padding-sm);
			}

			@include media('>=tablet') {
				@include inset-inline-start($card-padding-md);
			}
		}

		&.btn,
		> .btn {
			@include elevation(5);

			&:active {
				@include elevation(2);
			}
		}
	}

	.buttons.two {
		.btn:first-child {
			@include margin-inline-end(10px);

			@include media('>=phone') {
				@include margin-inline-end(30px);
			}
		}

		.btn {
			min-width: auto !important;
			font-size: 14px;
			padding: 10px;

			@include media('>=phone') {
				min-width: 150px !important;
				font-size: 20px;
				padding: 14px 16px;
			}
		}
	}

	form {
		width: 100%;

		:root.bright-theme {
			gap: 15px;
		}

		.mat-form-field:not(.accented) {
			.mat-form-field-infix {
				width: auto !important;
			}
		}
	}

	.mat-stepper-horizontal {
		background: transparent;

		.mat-horizontal-stepper-header-container {
			margin: 0 -15px;

			@include media('>=phone') {
				margin: 0 -20px;
			}

			@include media('>=tablet') {
				margin: 0 -24px;
			}
		}

		.mat-step-header {
			.mat-step-icon {
				&.mat-step-icon-state-done,
				&.mat-step-icon-selected {
					background: white;
					color: $var-color-primary;

					:root.transparent-theme &,
					:root.admin-theme &,
					:root.light-theme &,
					:root.equity & {
						background: $var-color-primary;
						color: white;
					}
				}
			}

			.mat-step-label {
				color: rgba(255, 255, 255, 0.54);

				&.mat-step-label-active {
					color: rgba(255, 255, 255, 0.87);
				}

				:root.transparent-theme &,
				:root.admin-theme &,
				:root.light-theme & {
					color: inherit;
					opacity: 0.54;

					&.mat-step-label-active {
						opacity: 0.87;
					}
				}
			}
		}

		.mat-stepper-horizontal-line {
			border-top-color: rgba(255, 255, 255, 0.2);

			:root.transparent-theme &,
			:root.admin-theme &,
			:root.light-theme & {
				background: currentColor;
				opacity: 0.2;
			}
		}

		.mat-horizontal-content-container {
			padding: 0 0 15px;
		}
	}

	.accented {
		display: block;

		input {
			border-bottom: 1px solid $white;

			&::selection {
				background: rgba(255, 255, 255, 0.99);
				color: $var-color-primary;

				:root.transparent-theme &,
				:root.admin-theme &,
				:root.light-theme &,
				:root.bright-theme &,
				:root.equity & {
					background: #{'rgba(var(--bp-color-primary-rgb), 0.99)'};
					color: White;
				}
			}
		}
	}

	span.accented,
	mat-form-field.accented input {
		color: $toxic-blue;
		letter-spacing: 1px;

		:root.transparent-theme &,
		:root.bright-theme &,
		:root.admin-theme &,
		:root.light-theme &,
		:root.equity & {
			color: $var-color-primary;
		}

		@include notBrightThemeOrBlox {
			@include font(24px, 28px, 700);

			@include media('>=phone') {
				@include font(29px, 32px, 700);
			}
		}
	}

	.btn-submit {
		@include notBrightThemeOrBlox {
			@include inset-inline-end($card-padding-xs);
			overflow: hidden;
			white-space: nowrap;

			@include media('<phone') {
				min-width: 150px;
			}

			@include media('>=phone') {
				@include inset-inline-start(auto);
				@include inset-inline-end($card-padding-sm);
			}

			@include media('>=tablet') {
				@include inset-inline-end($card-padding-md);
			}
		}

		@include brightTheme {
			width: 100%;
		}
	}

	.mat-form-field.mat-form-field-type-mat-select,
	.mat-form-field .mat-select-arrow,
	.mat-form-field .mat-select-value,
	.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
		font-size: inherit;
		line-height: inherit;
		font-weight: inherit;
		color: $var-color-text-primary;
	}

	.mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
		color: $var-color-error;
	}

	bp-page-note {
		position: relative;

		@include notBrightThemeOrBlox {
			margin-top: 1rem;
			font-size: 11px;
		}

		@include brightTheme {
			font-size: $bright-theme-mat-body-1-font-size;
		}
	}
}

.secure-logos {
	display: flex;
	flex-flow: column;
	align-self: flex-end;
	transition: filter $transition-props;
	filter: grayscale(1);
	opacity: 0.8;

	.left & {
		margin-bottom: -20px !important;
		flex-flow: wrap-reverse;
		@include margin-inline-end(auto);
	}

	.right & {
		@include media('<tablet') {
			margin-bottom: -20px;
			flex-flow: wrap-reverse;
			@include margin-inline-end(auto);
		}

		@include media('>=tablet') {
			margin-top: -50px;
			margin-bottom: auto;
		}
	}

	> img {
		width: 62px;

		.left & {
			height: intrinsic;
			@include margin-inline-end(0.5rem);
			margin-bottom: 0.25rem;
		}

		.right & {
			@include media('<335px') {
				width: 45px;
			}

			@include media('<tablet') {
				height: intrinsic;
				@include margin-inline-end(0.5rem);
				margin-bottom: 0.25rem;
			}

			@include media('>=tablet') {
				margin-bottom: 0.5rem;

				&.visa {
					margin-top: -5px;
				}
			}
		}
	}

	:root:not(.payment-card-token-checkout) .left & {
		@include media('<tablet') {
			display: none;
		}
	}

	.installments .right & {
		@include media('>=tablet') {
			display: none;
		}
	}
}
