$amber-palette: mat-palette($mat-amber, 700, 100, 900);

@mixin pulsing-line($bottom: 0px) {
	&:before,
	&:after {
		content: '';
		height: 1px;
		background: var(--bp-form-field-pending-color, mat-color($amber-palette));
		position: absolute;
		bottom: $bottom;
		width: 100%;
		transform-origin: 50%;
		animation: pulsing 1.4s ease infinite;
	}

	&:after {
		transition: opacity $transition-props;
		opacity: 0;
		height: 2px;
	}
}

.mat-form-field.form-field-pending {
	&.mat-form-field-appearance-outline {
		.mat-form-field-outline {
			@include pulsing-line();
		}

		&.mat-focused,
		.mat-form-field-flex:hover {
			.mat-form-field-outline:after {
				opacity: 1;
				height: 1px;
			}
		}
	}

	&.mat-form-field-appearance-legacy {
		.mat-form-field-underline {
			@include pulsing-line();
		}

		&.mat-focused .mat-form-field-flex,
		.mat-form-field-flex:hover,
		.mat-form-field-flex:focus {
			+ .mat-form-field-underline:after {
				bottom: -1px;
				opacity: 1;
			}
		}
	}

	&.mat-form-field-appearance-fill {
		.mat-form-field-underline {
			@include pulsing-line($bottom: 1px);
		}

		&.mat-focused .mat-form-field-flex,
		.mat-form-field-flex:hover,
		.mat-form-field-flex:focus {
			+ .mat-form-field-underline:after {
				bottom: 0;
				opacity: 1;
			}
		}
	}
}

@keyframes pulsing {
	0% {
		transform: scaleX(0.2);
	}

	50% {
		transform: scaleX(0.95);
	}

	100% {
		transform: scaleX(0.2);
	}
}
