body {
	-ms-overflow-style: scrollbar;
}

:root {
	&.mpi,
	&.nested-inside-another-checkout,
	&.standalone {
		&,
		body {
			height: 100%;
		}
	}

	&:not(.standalone).bp-three-ds-processing-result-callback-page {
		visibility: hidden;
	}
}
