:root {
	&.light-theme.equity {
		--bp-color-primary-rgb: 1, 175, 170; // #01AFAA
	}

	&.dark-theme.vantagefx {
		--bp-color-primary-rgb: 36, 80, 215; // #2450d7
		--bp-color-text-primary-rgb: #{$white-rgb};
		--bp-color-text-secondary-rgb: #{$white-rgb}, 0.65;

		.card {
			background: transparent !important;
			box-shadow: none !important;
		}
	}

	&.etihad {
		--bp-font-size: 16px;
		--bp-line-height: 22.4px;
		--bp-font-family: 'Roboto', sans-serif;
		--bp-color-primary-rgb: 189, 139, 19; //#bd8b13
		--bp-color-text-primary-rgb: 78, 76, 74;
		--bp-color-text-secondary-rgb: 78, 76, 74;
	}

	&.simplex-leonardo {
		--bp-color-primary-rgb: #{toRGB(#b5003b)};
	}

	&.advance-market {
		--bp-color-primary-rgb: 183, 210, 67; //#B7D243
		--bp-color-text-primary-rgb: 0, 25, 55; //#001937
		--bp-color-text-secondary-rgb: 0, 25, 55; //#001937
		--bp-input-underline-color-rgb: 218, 218, 218;

		bp-payment-method-button {
			.box {
				box-shadow: none;
				border: 1px solid $var-color-primary;

				.hover-caption {
					color: $var-color-text-primary !important;
					font-weight: 600;

					.btn {
						border-color: transparent !important;
						color: $var-color-primary;
						background: $var-color-text-primary;
					}
				}
			}
		}

		.btn-submit:not([disabled]) {
			color: $var-color-text-primary !important;
		}

		bp-processing-stub > div {
			background-color: $var-color-text-primary !important;

			h3 {
				color: $var-color-primary;
			}

			> div {
				font-size: 22px !important;
			}

			> * {
				font-weight: 600 !important;
			}
		}

		.card {
			box-shadow: none;
			border: 1px solid $var-color-primary;
		}
	}

	&.fortrade {
		--bp-color-primary-rgb: 32, 147, 254; // #2093FE;
		--bp-color-error-rgb: 202, 34, 36; // #ca2224

		bp-root {
			padding: 0;

			> .wrapper {
				box-shadow: none !important;

				> bp-header .user-info {
					color: $var-color-text-primary !important;
				}
			}

			.card {
				background: transparent;
				border-radius: unset;
				box-shadow: none;

				.title {
					color: $var-color-primary;
				}

				&:before {
					display: none;
				}

				.btn-submit {
					&:not([disabled]) {
						background: $var-color-primary !important;
						color: white;
					}
				}
			}
		}

		&.dark-theme {
			bp-root {
				> .wrapper {
					&,
					bp-header {
						background: #3f4656 !important;
					}
				}

				.card {
					border: 1px solid #6c7888;
				}
			}
		}

		&.light-theme {
			bp-root {
				> .wrapper {
					&,
					bp-header {
						background: transparent !important;
					}
				}

				.card {
					border: 1px solid #ededed;
				}
			}
		}
	}

	&.blockkoin {
		--bp-color-primary-rgb: 0, 206, 126; // #00ce7e;
		--bp-color-success-rgb: 0, 206, 126; // #00ce7e;
		--bp-color-error-rgb: 170, 50, 45; // #aa322d
		--bp-color-warning-rgb: 253, 183, 26; // #fdb71a
		--bp-font-family: 'Calibri', 'Helvetica Neue', 'Helvetica', sans-serif;

		bp-root {
			.card {
				.btn-submit {
					text-transform: uppercase;
				}
			}
		}

		.hover-caption .btn {
			text-transform: uppercase;
		}
	}

	&.dark-theme.century {
		$color-dark-rgb: 54 58 54; // #363A36;
		$color-primary-rgb: 241 196 0; // #F1C400;

		--bp-color-primary-rgb: #{$color-primary-rgb};
		--bp-color-dark-rgb: #{$color-dark-rgb};
		--bp-color-error-rgb: #{$light-theme-error-rgb};
		--bp-color-success-rgb: #{$light-theme-success-rgb};
		--bp-color-warning-rgb: #{$light-theme-warning-rgb};
		--bp-input-underline-color-rgb: #{$color-primary-rgb};

		input {
			caret-color: $var-color-dark;
		}

		.secure-logos {
			filter: invert(0.8);
		}

		.card {
			--bp-color-text-primary-rgb: #{$color-dark-rgb};
			--bp-color-text-secondary-rgb: #{$color-dark-rgb};

			background: white;

			&:before {
				display: none;
			}

			.card-logo {
				.tick {
					color: $var-color-success;
				}

				.cross {
					color: $var-color-error;
				}

				.hourglass {
					color: $var-color-warning;
				}
			}
		}

		.payment-card {
			&.submitting {
				.btn-submit {
					opacity: 0;
				}
			}
		}

		.accented {
			color: $var-color-primary;
		}

		.mat-form-field-ripple {
			background-color: $var-color-primary;
		}

		.mat-form-field {
			&:hover,
			&.mat-focused {
				&:not(.mat-form-field-invalid) {
					.mat-form-field-label {
						color: unset;
					}

					.mat-form-field-ripple {
						background-color: $var-color-primary;
					}
				}
			}
		}

		.btn {
			background: $var-color-primary;
			color: $var-color-dark;

			&[disabled] {
				filter: grayscale(1);

				&:hover {
					background: $var-color-primary;
					color: $var-color-dark;
				}
			}

			&:not([disabled]):hover {
				background: $var-color-dark;
				color: $var-color-primary;
			}
		}

		.btn-submit {
			z-index: 0;

			bp-dots-wave {
				display: none;
			}
		}
	}

	&.exinity {
		--bp-color-primary-rgb: 255, 90, 36; // #FF5A24;
		--bp-color-success-rgb: 80, 186, 0; // #50ba00;
		--bp-color-error-rgb: 186, 0, 0; // #ba0000;
		// --bp-color-warning-rgb: 253, 183, 26; // #fdb71a
		--bp-color-text-primary-rgb: 13, 20, 30; // #0D141E
		--bp-color-text-secondary-rgb: 13, 20, 30; //#0D141E
		--bp-input-underline-color-rgb: 173, 177, 184; // #ADB1B8

		background: linear-gradient(180deg, #252842 0%, #10151f 100%);

		body,
		button,
		span,
		p,
		input,
		textarea {
			letter-spacing: -0.5px;
		}

		.card .title {
			font-size: 18px;
			font-weight: 600;
			line-height: 22px;
			letter-spacing: -0.6px;
		}

		.mat-button.back-button {
			color: white;
		}

		form > .row > .left {
			gap: 0.5rem;
		}

		h4 {
			font-size: 21px;
			line-height: 26px;
		}

		bp-amount {
			h4 {
				color: #5c5d68;
			}

			.total-amount span.accented {
				font-size: 28px;
				line-height: 34px;
				color: $var-color-text-primary;
			}
		}

		input {
			font-size: 19px !important;
			line-height: 23px !important;

			@include placeholder {
				font-size: 19px !important;
				line-height: 23px !important;
			}
		}

		.btn {
			font-size: 20px;
			line-height: 24px;
		}

		.secure-logos {
			opacity: 0.4;
		}

		.card.status-card {
			color: $var-color-text-primary;
			border-color: transparent;
			outline-color: transparent;

			.card-table {
				font-weight: 500;
			}

			.subtext {
				font-weight: 600;
			}

			&.success-card {
				.card-logo {
					color: $var-color-success;
				}
			}
			&.declined-card {
				.card-logo {
					color: $var-color-error;
				}
			}
		}
	}

	&.funded-peaks {
		--bp-color-text-primary-rgb: #{toRGB(white)};
		--bp-color-primary-rgb: #{toRGB(#01d746)};

		&.local {
			background: #021631;
		}
	}
}
