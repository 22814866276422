:root.dark-theme {
    --bp-color-primary-rgb: #{toRGB($color-primary)};
    --bp-color-error-rgb: #{toRGB($dark-theme-error)};
    --bp-color-text-primary-rgb: #{$white-rgb};
    --bp-color-text-secondary-rgb: #{$white-rgb};
    --bp-color-dark-rgb: #{toRGB($dark)};
    --bp-input-underline-color-rgb: #{$white-rgb};

	background: $var-color-dark;
}
