:root.marabu {
	$var-marabu-color-primary: #464D47;

	bp-card-success-page .btn, .btn.btn-submit {
		&:not(:disabled) {
			border-color: transparent !important;
			color: white;
			background: $var-marabu-color-primary;

			&:hover {
				color: $var-marabu-color-primary;
				background: white;
			}
		}
	}

	.card span.accented {
		color: $var-marabu-color-primary !important;
	}

	.total-amount input {
		color: $var-marabu-color-primary !important;
		caret-color: $var-marabu-color-primary !important;
	}
}
