html.bright-theme.paywith-checkout {
	.paywith-logo {
		max-height: 17px;
	}

	.page-amount {
		display: flex;
		align-items: center;
		justify-content: space-between;

		.amount {
			margin: 0;
		}
	}

	.status-card {
		.card-content {
			font-size: $bright-theme-mat-body-1-font-size;
			margin-top: -0.5rem !important;

			.summary {
				display: flex;
				flex-flow: column;
				gap: 0.5rem;

				.summary-item {
					display: flex;
					justify-content: space-between;

					.summary-item-value {
						font-weight: 500;
					}
				}
			}

			.transactions-summary {
				display: grid;
				grid-template-columns: auto 1fr min-content;
				grid-auto-rows: 30px;
				align-items: center;
				row-gap: 1rem;
				column-gap: 0.5rem;

				.transaction {
					display: contents;

					.psp-logo {
						height: 100%;
						max-width: 75px;
						justify-self: center;

						bp-payment-option-logo {
							.logo-name {
								display: none;
							}

							.logo {
								&,
								img {
									height: 100%;
								}
							}
						}
					}

					.date {
						white-space: nowrap;
						text-align: right;
					}

					.amount {
						text-align: right;
					}
				}
			}
		}
	}
}
