:root.transparent-theme.james-allen-bluenile {
	--bp-font-weight: 400;
	--bp-font-size: 14px;
	--bp-line-height: 1.5;
	--bp-font-family: 'Gantari', sans-serif;

	--bp-input-label-text-transform: capitalize;
	--bp-color-primary-rgb: #{toRGB(#151542)};
	--bp-color-error-rgb: #{toRGB(#FC1F62)};
	--bp-color-text-primary-rgb: #{toRGB(#151542)};
	--bp-color-text-secondary-rgb: #{toRGB(#151542)};

	bp-root,
	bp-header,
	main {
		padding: 0 !important;
	}

	bp-header {
		display: none;
	}

	bp-root {
		padding-top: 15px !important;
	}

	.card {
		border-radius: unset;
		background: transparent;
		box-shadow: none;
		padding: 0;
		min-height: unset;

		.title {
			display: none;
		}

		form > .row {
			flex-flow: column;
			gap: 27.5px;
		}

		.left {
			gap: 5px;

			> .row {
				flex-flow: row !important;
				gap: 16px !important;
			}
		}

		.right {
			flex-flow: column;
			align-items: center;

			bp-page-note {
				order: 2;

				.reach--payment--banner {
					margin: 0;
				}
			}

			.secure-logos {
				order: 1;
				margin: 0 auto;
				flex-flow: wrap-reverse;
				margin-bottom: 20px;
				gap: 0.5rem;

				> img {
					margin: 0 !important;

					&.visa {
						width: 25px !important;
					}
				}
			}

			.credit-detail {
				order: 0;
				margin-bottom: 32px;
				margin-top: 0 !important;

				button {
					position: relative;
					right: unset;
					bottom: unset;
					top: unset;
					@include inset-inline-start(unset);
					z-index: 0;

					bp-dots-wave {
						display: none;
					}
				}

				&,
				button {
					width: 100%;
				}
			}
		}

		button.btn {
			text-transform: capitalize;
			height: 40px;
			padding: 0;
			box-shadow: none !important;
			font-size: 14px;
			font-weight: 600;
			border-radius: 4px;

			&:disabled {
				background: #8a8aa0;
				color: #d9d9d9
			}

			&:not(:disabled) {
				&:focus,
				&:hover {
					background: #2c2c55;
					color: white;
				}
			}
		}

		&.status-card {
			.card-float-button-position {
				bottom: 0;
				justify-content: center;
				display: flex;
			}
		}
	}

	bp-amount {
		display: none;
	}

	input {
		font-size: 14px !important;
		line-height: 20px !important;
		font-weight: 400 !important;
		height: 20px !important;
		position: relative;
		color: currentColor !important;

		@include placeholder {
			font-size: 14px !important;
			line-height: 20px !important;
			font-weight: 400 !important;
			color: #a1a1a1;
		}
	}

	bp-payment-card-brand-icon {
		top: 2px !important;
		@include inset-inline-end(-5px !important);
	}

	.mat-form-field-appearance-outline {
		padding-top: 1.2rem;

		color: #898989;

		&:focus-within,
		&:hover {
			color: #151542;
		}

		.mat-form-field-outline {
			&-start,
			&-gap,
			&-end {
				border-top-color: currentColor !important;
			}

			&-start {
				border-radius: 8px 0 0 8px;
				min-width: 10px;
			}

			&-end {
				border-radius: 0 8px 8px 0;
				min-width: 10px;
			}
		}

		.mat-form-field-outline-thick {
			.mat-form-field-outline {
				&-start,
				&-gap,
				&-end {
					border-width: 1px;
				}
			}
		}

		.mat-form-field-infix {
			padding: 5.5px 0 11px 0;
		}

		.mat-form-field-flex {
			padding: 0 16px;
		}

		// .mat-form-field-flex:hover .mat-form-field-outline-thick {
		// 	color: unset !important;
		// }

		&.mat-focused:not(.mat-form-field-invalid) {
			// .mat-form-field-outline-thick {
			// 	color: unset !important;
			// }

			// .mat-form-field-label {
			// 	color: unset !important;
			// }
		}

		&.mat-form-field-invalid {
			.mat-form-field-outline-thick {
				color: $var-color-error !important;
				background: #fff2f2;
			}
		}

		.mat-form-field-label-wrapper {
			top: -30px;
			@include inset-inline-start(-15px);

			.mat-form-field-label {
				transform: unset;
				margin-top: -28px;
				line-height: 20px;
				font-size: 14px;
			}
		}

		.mat-form-field-subscript-wrapper {
			padding: 0;
			margin: 0;

			> * {
				font-size: 11px;
				line-height: 16px;
				margin-top: 8px;
			}
		}
	}
}