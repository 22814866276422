@mixin iconsize($size) {
	font-size: $size;
	width: $size;
	height: $size;
}

// Position Absolute
@mixin absolutePosition($top, $right, $bottom, $left) {
	position: absolute;
	top: $top;
	bottom: $bottom;
	@include inset-inline-start($left);
	@include inset-inline-end($right);
}

@mixin notBrightThemeOrBlox() {
	:root:not(.bright-theme):not(.blox) & {
		@content;
	}
}

@mixin brightTheme() {
	:root.bright-theme & {
		@content;
	}
}

@mixin deepNotBrightThemeOrElement() {
	::ng-deep :root:not(.bright-theme):not(.blox) & {
		@content;
	}
}

@mixin deepBrightTheme() {
	::ng-deep :root.bright-theme & {
		@content;
	}
}

@mixin hoverBoxShadow() {
	@include elevation(4, $elevation-color, 0.5);
}
