:root.transparent-theme.passport-card {
	$primary-color: #2d0104;

	--bp-color-primary-rgb: #{toRGB($primary-color)};
	--bp-color-text-primary-rgb: #{toRGB($primary-color)};
	--bp-color-text-secondary-rgb: #{toRGB(#67686a)};
	--bp-color-error-rgb: #{toRGB(#ad0f0f)};
	--bp-input-label-text-transform: capitalize;

	main {
		padding: unset !important;
	}

	.card {
		background: transparent;
		box-shadow: none;
		padding: 10px !important;

		.title {
			display: none;
		}

		form > .row {
			flex-flow: column !important;
			flex: unset;

			.left {
				gap: 0;
			}

			.right {
				display: block;

				bp-amount,
				.secure-logos {
					display: none;
				}

				.credit-detail {
					margin-top: 5px;
				}

				.btn-submit {
					position: static;
					width: 100% !important;
					background: linear-gradient(180deg, #e10514 0%, #a2191c 100%);
					box-shadow: 0px 6px 11px 0px rgba(225, 5, 20, 0.25);
					height: 48px;
					border-radius: 50px;
					padding: 12px 24px;
					font-weight: 400;
					font-size: 16px;
					line-height: 24px;
					display: flex;
					align-items: center;
					justify-content: center;
					gap: 8px;

					&:after {
						content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"> <path d="M13.4792 10.8334H4.16671C3.9306 10.8334 3.73268 10.7536 3.57296 10.5938C3.41324 10.4341 3.33337 10.2362 3.33337 10.0001C3.33337 9.76398 3.41324 9.56606 3.57296 9.40634C3.73268 9.24662 3.9306 9.16676 4.16671 9.16676H13.4792L9.39587 5.08342C9.22921 4.91676 9.14935 4.72231 9.15629 4.50009C9.16324 4.27787 9.25004 4.08342 9.41671 3.91676C9.58337 3.76398 9.77782 3.68412 10 3.67717C10.2223 3.67023 10.4167 3.75009 10.5834 3.91676L16.0834 9.41676C16.1667 9.50009 16.2257 9.59037 16.2605 9.68759C16.2952 9.78481 16.3125 9.88898 16.3125 10.0001C16.3125 10.1112 16.2952 10.2154 16.2605 10.3126C16.2257 10.4098 16.1667 10.5001 16.0834 10.5834L10.5834 16.0834C10.4306 16.2362 10.2396 16.3126 10.0105 16.3126C9.78129 16.3126 9.58337 16.2362 9.41671 16.0834C9.25004 15.9168 9.16671 15.7188 9.16671 15.4897C9.16671 15.2605 9.25004 15.0626 9.41671 14.8959L13.4792 10.8334Z" fill="white"/> </svg>');
						height: 20px;
					}

					&:disabled {
						opacity: 20%;
						box-shadow: none;
					}
				}
			}
		}
	}

	.mat-form-field {
		.mat-form-field-flex {
			height: 48px;
			padding: 7px 24px !important;
			border-radius: 50px;
			border: 1px solid #dfdad5;
			transition: border-color $transition-props;
			background: white;

			.mat-form-field-infix {
				padding: 0px;

				input {
					height: auto;
					font-style: normal;
					font-size: 15px;
					font-weight: 400;
					line-height: 20px;
					color: $var-color-text-secondary !important;

					@include placeholder {
						font-size: 15px !important;
						font-weight: 400 !important;
						line-height: 20px !important;
						color: $var-color-text-secondary !important;
					}
				}

				bp-payment-card-brand-icon {
					bottom: 5px;
				}
			}

			.mat-form-field-label {
				font-size: 14px;
				font-style: normal;
				font-weight: 500;
				line-height: 14px;
				color: $var-color-text-primary;

				.mat-form-field-required-marker {
					display: none;
				}
			}
		}

		&.mat-form-field-invalid {
			.mat-form-field-flex {
				border-color: $var-color-error;
			}
		}

		.mat-form-field-underline {
			display: none;
		}

		bp-validation-error {
			font-size: 12px;
			font-style: normal;
			font-weight: 500;
			line-height: 20px;
		}
	}
}
