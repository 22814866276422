:root {
	--bp-font-family: #{$font-family};
	--bp-font-weight: #{$font-weight};
	--bp-font-size: #{$font-size};
	--bp-line-height: #{$line-height};
	--bp-font: var(--bp-font-weight) var(--bp-font-size) / var(--bp-line-height) var(--bp-font-family);

	--bp-h1-font-size: 30px;
	--bp-h2-font-size: 27px;
	--bp-h3-font-size: 24px;
	--bp-h4-font-size: 20px;
	--bp-h5-font-size: 17px;
	--bp-h6-font-size: 15px;
	--bp-h1-font-weight: 500;
	--bp-h2-font-weight: 500;
	--bp-h3-font-weight: 400;
	--bp-h4-font-weight: 400;
	--bp-h5-font-weight: 300;
	--bp-h6-font-weight: 300;
	--bp-input-label-text-transform: uppercase;
	--bp-color-dark-rgb: #{toRGB($dark)};
	--bp-mat-body-1-font-size: 14px;
	--bp-mat-body-1-font-weight: 400;
	--bp-mat-body-1-line-height: 20px;
	--bp-mat-body-2-font-size: 14px;
	--bp-mat-body-2-font-weight: 500;
	--bp-mat-body-2-line-height: 24px;
	--bp-color-text-primary-rgb: #{$light-theme-gray-rgb};
	--bp-color-text-secondary-rgb: #{$light-theme-gray-rgb};

	color: $var-color-text-primary;
	font: var(--bp-font);

	&.admin-theme,
	&.transparent-theme,
	&.light-theme {
		--bp-color-dark-rgb: #{$light-theme-gray-rgb};
		--bp-color-primary-rgb: #{$light-theme-blue-rgb};
		--bp-color-error-rgb: #{$light-theme-error-rgb};
		--bp-color-success-rgb: #{$light-theme-success-rgb};
		--bp-color-warning-rgb: #{$light-theme-warning-rgb};
		--bp-input-underline-color-rgb: #{$light-theme-gray-rgb};
	}
}
