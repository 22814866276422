.payment-option-box {
	transition: all $transition-props;
	overflow: hidden;
	background: $white;
	display: flex;
	position: relative;
	text-align: center;
	width: 100%;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	gap: 10px;
	line-height: 1;

	@include notBrightThemeOrBlox {
		@include elevation(4, $elevation-color, 0.5);
		border-radius: $border-radius;
		padding: 20px;
		aspect-ratio: 1 /1;
		flex-flow: column nowrap;
		max-height: 180px;

		&:not(.has-hover-caption):hover {
			@include elevation(12, $elevation-color, 0.5);
		}

		@include media('<phone-lg') {
			max-height: 120px;
		}

		@include media('>=tablet') {
			&:hover .hover-caption {
				opacity: 1;
				visibility: visible;
			}
		}
	}

	@include brightTheme {
		height: $bright-theme-row-height;
		border-radius: $bright-theme-border-radius;
		border: 1px solid $color-border;
		background: white;
		padding: 5px 0;
		cursor: pointer;

		&:focus,
		&:hover {
			@include hoverBoxShadow();
		}

		.logo.paywith {
			max-width: 150px;
		}

		.hover-caption {
			display: none;
		}
	}

	:root.dark-theme & {
		box-shadow: none;
		color: $var-color-dark;

		@include media('>=tablet') {
			&.has-hover-caption:hover {
				background: $var-color-dark;
				color: $white;
			}

			&:not(.has-hover-caption):hover {
				outline: 3px solid $var-color-primary;
			}
		}

		.hover-caption {
			background: $var-color-dark;

			.payment-option-cta-btn {
				@include elevation(9);
			}
		}
	}

	:root.admin-theme & {
		box-shadow:
			0 3px 3px -2px rgba(0, 0, 0, 0.2),
			0 3px 4px 0 rgba(0, 0, 0, 0.1),
			0 1px 8px 0 rgba(0, 0, 0, 0.07);

		&:hover {
			box-shadow:
				0 6px 6px -3px rgba(0, 0, 0, 0.12),
				0 10px 14px 1px rgba(0, 0, 0, 0.1),
				0 4px 18px 3px rgba(0, 0, 0, 0.07);
		}
	}

	:root.admin-theme &,
	:root.transparent-theme &,
	:root.light-theme & {
		.payment-option-cta-btn {
			border: 1px solid white;
		}

		&:hover .mat-progress-bar {
			color: white;
		}
	}

	.hover-caption {
		transition: all $transition-props;
		@include fontsize(12px, 20px);
		@include absolutePosition(0, 0, 0, 0);
		background: $var-color-primary;
		border-radius: 10px;
		color: $white;
		font-style: italic;
		opacity: 0;
		padding: 15px 10px;
		visibility: hidden;
		display: flex;
		flex-flow: column nowrap;
		align-items: center;
		justify-content: center;
		z-index: 1;

		@include media('>=610px') {
			@include fontsize(14px, 24px);
		}

		p {
			margin: 0 0 10px;

			@include media('>=phone') {
				margin: 0 0 20px;
			}
		}

		.payment-option-cta-btn {
			@include font(16px, 20px, 700);
			font-style: normal;
			height: 40px;
			padding: 10px 16px;
			min-width: 118px;
			cursor: pointer;
		}
	}
}
