:root.dark-theme.david-shields {
	--bp-font-weight: 400;
	--bp-font-size: 14px;
	--bp-line-height: 1.5;
	--bp-font-family: 'Open Sans', sans-serif;

	--bp-input-label-text-transform: capitalize;
	--bp-color-primary-rgb: #{toRGB(#E10514)};
	--bp-color-error-rgb: #{toRGB(#de5962)};
	--bp-color-text-primary-rgb: #{$white-rgb};
	--bp-color-text-secondary-rgb: #{$white-rgb};

	&.non-production {
		background: rgba(0, 0, 0, 0.8);

		bp-root {
			padding-top: 15px !important;
		}
	}

	bp-root,
	bp-header,
	main {
		padding: 0 !important;
	}

	bp-header {
		display: none;
	}

	main > .container {
		padding: 0 !important;
	}

	.wrapper {
		background: none !important;
	}

	.card,
	.card-container {
		max-width: 770px !important;
	}

	.card {
		border-radius: unset;
		background: transparent;
		box-shadow: none;
		padding: 0 !important;
		min-height: unset;

		&:before {
			box-shadow: none;
		}

		.title {
			display: none;
		}

		form > .row {
			flex-flow: column !important;
			gap: 30px;
		}

		.left {
			display: grid;
			grid-template-areas:
				'number number'
				'holder holder'
				'expire cvv'
				'saveCard saveCard';
			grid-template-columns: 1fr 1fr;
			column-gap: 30px;
			row-gap: 0;
			max-width: 600px;

			@include media('>phone-lg') {
				grid-template-areas:
				'number holder'
				'expire cvv'
				'saveCard saveCard';
			}

			bp-payment-card-number-input {
				grid-area: number;
			}

			.expiry-cvv {
				display: contents;

				bp-payment-card-expire-input {
					grid-area: expire;
				}

				bp-payment-card-cvv-input {
					grid-area: cvv;
				}
			}

			bp-payment-card-holder-input {
				grid-area: holder;
			}

			.save-card-checkbox {
				grid-area: saveCard;
				margin-top: 0 !important;
			}

			> .row {
				flex-flow: row !important;
				gap: 30px !important;
			}
		}

		.right {
			flex-flow: row-reverse !important;
			align-items: center;
			justify-content: space-between;

			bp-page-note {
				order: 2;

				.reach--payment--banner {
					margin: 0;
				}
			}

			.secure-logos {
				order: 1;
				align-self: unset;
				margin: unset;
				flex-flow: wrap-reverse;
				// margin-bottom: 20px;
				gap: 0.5rem;

				> img {
					margin: 0 !important;

					&.visa {
						width: 25px !important;
					}
				}
			}

			.credit-detail {
				order: 0;
				margin-top: 0 !important;

				button {
					position: relative;
					right: unset;
					bottom: unset;
					top: unset;
					@include inset-inline-start(unset);
					z-index: 0;

					bp-dots-wave {
						display: none;
					}
				}
			}
		}

		button.btn {
			text-transform: capitalize;
			height: 40px;
			padding: 0;
			box-shadow: none !important;
			font-size: 17px;
			font-weight: 400;
			border-radius: 0;
			background: $var-color-primary;
			color: white;

			&:disabled {
				background: #979797;
				color: #d9d9d9
			}

			&:not(:disabled):hover {
				background: rgba(var(--bp-color-primary-rgb), 0.9);
			}
		}

		&.status-card {
			.card-float-button-position {
				bottom: 0;
				justify-content: center;
				display: flex;
				position: static;
			}
		}
	}

	bp-amount {
		display: none;
	}

	input {
		font-size: 13px !important;
		line-height: 20px !important;
		font-weight: 400 !important;
		height: 20px !important;
		position: relative;
		color: currentColor !important;
		caret-color: currentColor !important;

		@include placeholder {
			font-size: 13px !important;
			line-height: 20px !important;
			font-weight: 400 !important;
			color: #979797;
		}
	}

	bp-payment-card-brand-icon {
		top: 2px !important;
		@include inset-inline-end(-5px !important);
	}

	.mat-form-field-appearance-outline {
		padding-top: 1.2rem;

		color: #333333;

		.mat-form-field-outline {
			background: white;
			color: white;
			opacity: 1 !important;

			&-start,
			&-gap,
			&-end {
				border-top-color: currentColor !important;
			}

			&-start {
				border-radius: 0 0 0 0;
				min-width: 10px;
			}

			&-end {
				border-radius: 0 0 0 0;
				min-width: 10px;
			}
		}

		.mat-form-field-outline-thick {
			opacity: 0 !important;

			.mat-form-field-outline {
				&-start,
				&-gap,
				&-end {
					border-width: 1px;
				}
			}
		}

		.mat-form-field-infix {
			padding: 2.5px 0 10px 0;
		}

		.mat-form-field-flex {
			padding: 0 16px;
		}

		// .mat-form-field-flex:hover .mat-form-field-outline-thick {
		// 	color: unset !important;
		// }

		&.mat-focused:not(.mat-form-field-invalid) {
			// .mat-form-field-outline-thick {
			// 	color: unset !important;
			// }

			// .mat-form-field-label {
			// 	color: unset !important;
			// }
		}

		&.mat-form-field-invalid {
			.mat-form-field-outline-thick {
				color: $var-color-error !important;
				background: #fff2f2;
			}
		}

		.mat-form-field-label-wrapper {
			top: -30px;
			@include inset-inline-start(-15px);

			.mat-form-field-label {
				transform: unset;
				margin-top: -28px;
				line-height: 20px;
				font-size: 14px;
			}
		}

		.mat-form-field-subscript-wrapper {
			padding: 0;
			margin: 0;

			> * {
				font-size: 11px;
				line-height: 16px;
				margin-top: 8px;

				&:not(.mat-form-field-hint-wrapper) {
					margin-bottom: 8px;
				}
			}
		}
	}
}