:root.transparent-theme.secure-pharma {
	$main-color:#BC3970;

	--bp-color-primary-rgb: #{$white-rgb}; //#ffe048
	--bp-color-text-primary-rgb: #{$white-rgb};
	--bp-color-text-secondary-rgb: #{$white-rgb}, 0.75;
	--bp-input-underline-color-rgb: #{$white-rgb}, 0.75;
	--bp-color-error-rgb:#{$white-rgb};
	--bp-color-success-rgb: #{$white-rgb};
	--bp-color-warning-rgb: #{$white-rgb};

	background: $main-color;

	.card {
		background: $main-color;
		box-shadow: none;
	}

	.payment-option-box,
	.hover-caption,
	.btn {
		color: $main-color;

		&:hover,
		&:focus {
			color: $main-color;
		}
	}

	.right .secure-logos {
		margin-top: 1rem;
		margin-bottom: unset !important;
	}
}
