.mat-error {
	color: $var-color-error;
}

.mat-button-focus-overlay {
	background: #000;
}

.mat-form-field {
	&:hover,
	&.mat-focused {
		.mat-form-field-label {
			color: white;

			:root.bright-theme &,
			:root.transparent-theme &,
			:root.admin-theme &,
			:root.light-theme &,
			:root.equity & {
				color: $var-color-primary;
			}
		}
	}

	&.mat-focused:not(.mat-form-field-invalid) .mat-form-field-ripple,
	.mat-form-field-ripple {
		background-color: white;

		:root.bright-theme &,
		:root.transparent-theme &,
		:root.admin-theme &,
		:root.light-theme &,
		:root.equity & {
			background-color: $var-color-primary;
		}
	}

	&.mat-form-field-invalid {
		mat-error,
		.mat-form-field-label {
			color: $var-color-error;
		}

		.mat-form-field-ripple {
			background-color: $var-color-error !important;
		}
	}

	&.mat-form-field-disabled {
		cursor: not-allowed;

		.mat-form-field-underline {
			background-image: linear-gradient(
				to right,
				rgba(255, 255, 255, 0.85) 0,
				rgba(253, 253, 253, 0.85) 33%,
				transparent 0
			) !important;
		}
	}

	.mat-form-field-wrapper,
	.mat-form-field-infix {
		padding-bottom: unset;
	}

	.mat-form-field-underline {
		background-color: $var-input-underline-color;
		bottom: unset;
	}

	.mat-form-field-label {
		text-transform: $var-input-label-text-transform;
		color: $var-color-text-secondary;

		mat-label {
			transition: color $transition-props;
		}
	}

	.mat-input-element {
		border: none !important;
	}

	.mat-form-field-subscript-wrapper {
		margin-top: 0.5em;
		font-size: 65%;
		position: static;

		> * {
			animation: slide 0.5s ease;
			transform: unset !important;
		}
	}

	&.readonly .mat-form-field-underline {
		display: none;
	}

	&.mat-form-field-appearance-standard {
		.mat-form-field-label {
			transform: translateY(-1.28125em) $mat-label-scale !important;
		}

		&.mat-form-field-type-mat-select .mat-select:not(.mat-select-empty) {
			.mat-select-arrow-wrapper {
				transform: unset;
			}
		}
	}

	&.mat-form-field-appearance-outline {
		&.mat-form-field-type-mat-select {
			@include brightTheme {
				.mat-form-field-infix {
					padding: 0.509em 0 0.404em 0;
				}

				.mat-select-arrow-wrapper {
					transform: unset;
				}
			}
		}

		.mat-form-field-wrapper {
			@include brightTheme {
				margin: 0;
				padding: 0;
			}
		}

		.mat-form-field-infix {
			padding: 0.3em 0 0.5em 0;

			@include brightTheme {
				padding: 0.409em 0 0.534em 0;
				border-top: 0.257em solid transparent;

				input {
					margin-top: 0;
				}
			}
		}

		.mat-form-field-flex {
			@include brightTheme {
				margin-top: 0;
			}
		}

		.mat-form-field-flex,
		.mat-form-field-subscript-wrapper {
			@include brightTheme {
				padding: 0 15px;
			}
		}

		.mat-form-field-subscript-wrapper {
			@include brightTheme {
				margin: 0;

				.mat-hint,
				.mat-error {
					margin-top: 0.5em;
				}
			}
		}

		.mat-form-field-outline {
			transition: color $transition-props;

			@include brightTheme {
				opacity: 1 !important;
				top: 0;

				[class^='mat-form-field-outline-'] {
					border-width: 1px;
				}
			}

			:root.bright-theme [dir='rtl'] & {
				// transform: scale(-1, 1);
			}
		}

		&:not(.mat-form-field-invalid) {
			@include brightTheme {
				color: $color-border;
			}
		}

		&:hover,
		&.mat-focused {
			.mat-form-field-outline-thick {
				color: white;

				:root.bright-theme &,
				:root.transparent-theme &,
				:root.admin-theme &,
				:root.light-theme {
					color: $var-color-primary;
				}
			}
		}

		&.mat-form-field-invalid {
			.mat-form-field-outline-thick {
				color: $var-color-error;
			}
		}

		&.mat-form-field-should-float .mat-form-field-label {
			@include brightTheme {
				transform: translateY(-0.93em) $mat-label-scale !important;
			}
		}

		.mat-form-field-label {
			@include brightTheme {
				top: 0.7588em;
				padding-top: 0.7135em;
			}
		}
	}
}

.mat-button,
.mat-form-field,
.mat-option,
.mat-select-value,
.mat-stepper-horizontal {
	font: var(--bp-font);
}

.mat-autocomplete-panel,
.mat-select-panel {
	@include elevation(4);

	background: white;
	color: $var-color-dark;

	.mat-option {
		transition: background $transition-props;

		&.mat-active:not(.mat-option-disabled),
		&:hover:not(.mat-option-disabled) {
			background: rgba(0, 0, 0, 0.04);
		}

		&.mat-selected.mat-active:not(.mat-option-disabled) {
			color: $var-color-primary !important;
		}

		&.mat-selected:not(.mat-active) {
			&:not(.mat-option-disabled) {
				color: $var-color-primary !important;

				:root.transparent-theme &,
				:root.admin-theme &,
				:root.light-theme & {
					color: white !important;
				}
			}

			&:not(.mat-option-multiple) {
				:root.transparent-theme &,
				:root.admin-theme &,
				:root.light-theme &,
				:root.equity & {
					background: $var-color-primary;
				}
			}
		}
	}
}

.mat-datepicker-toggle {
	color: $var-color-text-primary;
	transition: color $transition-props;

	.mat-form-field-invalid & {
		color: $var-color-error;
	}
}

.mat-checkbox {
	.mat-checkbox-frame {
		border-color: $var-color-text-primary;
	}

	&.mat-checkbox-checked,
	&.mat-checkbox-indeterminate {
		.mat-checkbox-background {
			background-color: $var-color-text-primary;
		}
	}

	&:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
		background: $var-color-primary !important;
	}

	.mat-checkbox-checkmark-path {
		stroke: $var-color-primary !important;

		:root.transparent-theme &,
		:root.bright-theme &,
		:root.admin-theme &,
		:root.light-theme & {
			stroke: white !important;
		}
	}

	.mat-checkbox-layout {
		white-space: normal;
	}
}

.mat-form-field-appearance-legacy .mat-hint,
.mat-hint {
	color: $var-color-text-secondary;
}

@keyframes slide {
	0% {
		max-height: 0;
	}

	100% {
		max-height: 50px;
	}
}

:root .cdk-overlay-pane:not(.mat-autocomplete-panel-above) {
	.saved-payment-cards-autocomplete-panel,
	.dial-code-countries-autocomplete-panel {
		margin-top: 0;
	}
}

bp-phone-input {
	.mat-form-field-label-wrapper {
		overflow: unset;

		label {
			margin-left: -1.9rem;
		}
	}
	.dial-code-button {
		margin-right: 0.5rem;
	}
}

bp-input {
	@include brightTheme {
		display: inline-flex;
	}
}
