:root.bright-theme {
    --bp-font-family: 'Roboto', 'Arial', 'Helvetica Neue', 'Helvetica', sans-serif;
	--bp-font-weight: 400;
	--bp-font-size: #{$bright-theme-mat-body-2-font-size};
	--bp-line-height: #{$line-height-golden-ratio};
	--bp-mat-body-1-font-size: #{$bright-theme-mat-body-1-font-size};
	--bp-mat-body-1-font-weight: #{$bright-theme-mat-body-1-font-weight};
	--bp-mat-body-1-line-height: #{$line-height-golden-ratio};
	--bp-mat-body-2-font-size: #{$bright-theme-mat-body-2-font-size};
	--bp-mat-body-2-font-weight: #{$bright-theme-mat-body-2-font-weight};
	--bp-mat-body-2-line-height: #{$line-height-golden-ratio};

	--bp-h1-font-size: 24px;
	--bp-h2-font-size: 22px;
	--bp-h3-font-size: 20px;
	--bp-h4-font-size: 18px;
	--bp-h5-font-size: 17px;
	--bp-h6-font-size: 15px;

	--bp-input-label-text-transform: capitalize;
    --bp-color-text-primary-rgb: #{toRGB($color-midnight)};
	--bp-color-text-secondary-rgb: #{toRGB($color-gray)};
	--bp-color-dark-rgb: #{toRGB($color-midnight)};
	--bp-color-primary-rgb: #{$light-theme-blue-rgb};
	--bp-color-error-rgb: #{toRGB($color-bittersweet)};
	--bp-color-success-rgb: #{$light-theme-success-rgb};
	--bp-color-warning-rgb: #{$light-theme-warning-rgb};
	--bp-input-underline-color-rgb: #{toRGB($color-gray)};

	bp-root {
		padding: unset;
		display: flex;
		align-items: flex-start;
		justify-content: center;
		gap: 1.5rem;

		@include media('>=phone-lg') {
			align-items: center;
		}

		> div.wrapper {
			max-width: 500px;
			padding: 10px 15px;
			gap: 25px;
			position: relative;
			border-radius: 12px;
			box-shadow: none;
			min-height: unset;

			@include media('>=phone-lg') {
				border: 1px solid $color-border;
			}

			@include media('<phone-lg') {
				min-height: 100%; // min height instead of height due to bp-root align items flex-start
			}

			> bp-header,
			> main {
				padding: 0 !important;
			}

			> bp-header {
				min-height: unset;
			}

			> main {
				min-height: 125px;

				> .container {
					padding: 0;
				}
			}
		}
	}
}