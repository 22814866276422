* {
	outline: none;
}

.checkout-lg .card-container {
	max-width: 700px;

	@include notBrightThemeOrBlox {
		form > .row > .right {
			flex: 0 1 33.5%;
		}
	}
}

.card-container {
	$max-width: 625px;
	max-width: $max-width;
	width: 100%;
}

/* btn style */
.btn {
	color: $var-color-primary;
	background: $white;
	cursor: pointer;
	border: none;
	display: inline-block;
	min-width: 180px;
	outline: none;
	text-align: center;
	vertical-align: top;
	transition: all $transition-props;

	@include notBrightThemeOrBlox {
		height: $btn-height;
		border-radius: 5px;
		padding: 14px 16px;
		@include font(20px, 20px, 700);
	}

	@include brightTheme {
		height: $bright-theme-row-height;
		border-radius: $bright-theme-control-border-radius;
		padding: 12px 16px;
		@include font(19px, 1, 500);
	}

	:root.transparent-theme &,
	:root.admin-theme &,
	:root.light-theme &,
	:root.bright-theme &,
	:root.equity & {
		color: white;
		background: $var-color-primary;
	}

	&.invert {
		background: $var-color-primary;
		color: white;

		&:focus,
		&:hover {
			background: white;
			color: $var-color-primary;
		}

		:root.transparent-theme &,
		:root.admin-theme &,
		:root.light-theme &,
		:root.equity & {
			color: $var-color-primary;
			background: white;

			&:focus,
			&:hover {
				background: $var-color-primary;
				color: white;
			}
		}
	}

	&:focus,
	&:hover {
		background: $var-color-primary;
		color: $white;

		@include notBrightThemeOrBlox {
			@include elevation(5);
		}

		@include brightTheme {
			@include elevation(4, $elevation-color, 0.5);
		}

		:root.transparent-theme &,
		:root.admin-theme &,
		:root.light-theme &,
		:root.equity & {
			background: white;
			color: $var-color-primary;
		}
	}

	&:active {
		@include elevation(2);
	}

	&[disabled] {
		cursor: not-allowed !important;
		box-shadow: none !important;

		&,
		&:focus,
		&:hover {
			background: $white;
			color: $gray;

			@include brightTheme {
				background: $color-wild-sand;
				color: $color-silver;
			}

			:root.transparent-theme &,
			:root.admin-theme &,
			:root.light-theme & {
				color: white;
				background: lighten($light-theme-gray, 30%);
			}
		}
	}

	&.btn-submit {
		overflow: hidden;
	}
}

.mat-icon-button,
.btn-icon {
	border: none;
	background: transparent;
	padding: 0;
	transition: color $transition-props;

	&.sm {
		$btn-size: 27px;
		@include size($btn-size);
		line-height: $btn-size;

		.mat-icon {
			$icon-size: 18px;
			@include size($icon-size);
			font-size: $icon-size;
		}
	}

	:root.admin-theme & {
		color: $var-color-primary !important;
	}

	:root.dark-theme & {
		color: white !important;
	}

	.mat-icon {
		line-height: 1 !important;
	}

	.mat-button-focus-overlay {
		background-color: $var-color-primary !important;

		:root.dark-theme & {
			background-color: white !important;
		}
	}

	&.inherit-color {
		color: inherit !important;
	}

	&.gray {
		color: $gray !important;

		&:not([disabled]) {
			&:focus,
			&:hover {
				color: $var-color-dark !important;
			}
		}
	}

	&:focus,
	&:hover {
		.mat-button-focus-overlay {
			opacity: 0.3;

			:root.admin-theme & {
				opacity: 0.12;
			}
		}
	}

	&.lg {
		$lg: 40px;
		width: $lg;
		height: $lg;
		line-height: 38px;

		$lg-icon: $lg - 8px;
		.mat-icon {
			width: $lg-icon;
			height: $lg-icon;
			font-size: $lg-icon;
		}
	}

	&[disabled] {
		opacity: 0.4;
		cursor: not-allowed !important;

		.mat-button-focus-overlay {
			opacity: 0;
		}
	}
}

.warning {
	color: $warning-text !important;

	:root.transparent-theme &,
	:root.admin-theme &,
	:root.light-theme & {
		color: $light-theme-warning !important;
	}
}

.mat-icon {
	&.mat-icon {
		&-sm {
			@include iconsize(18px);
		}
	}
}

b {
	display: contents;
}

.text-primary {
	color: $var-color-primary;
}

.thin-notrack-scrollbar {
	@include thin-notrack-scrollbar();
}

.mat-button.back-button {
	font-size: 12px;
	margin-bottom: 0.5rem;

	&,
	&[disabled] {
		color: $var-color-text-primary;
	}

	&[disabled] {
		cursor: not-allowed;
	}

	&:before {
		content: ' ➜';
		transform: rotate(180deg);
		display: inline-block;
		vertical-align: middle;
		position: relative;
		border-width: 0;
		margin-right: 0.5rem;

		@include brightTheme {
			top: -1px;
		}
	}

	@include brightTheme {
		color: $var-color-primary;
		line-height: 1;
		padding: 5px;
		margin-left: -5px;
		margin-bottom: 15px;

		&:before {
			margin-right: 0.25rem;
		}
	}
}

bp-payment-card-brand-icon {
	@include brightTheme {
		bottom: 12px;
	}

	bp-svg-icon {
		border: 1px solid $color-lightboxgray;
		border-radius: 4px;
		background: white;
	}
}

:root.mpi .psp-iframe-outlet iframe:not(.invisible) {
	position: fixed;
	height: 100% !important;
	border-radius: 0 !important;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 1;
}
