:root.transparent-theme.james-allen {
	--bp-font-weight: 400;
	--bp-font-size: 14px;
	--bp-line-height: 1.5;
	--bp-font-family: 'Nunito Sans', sans-serif;

	--bp-input-label-text-transform: capitalize;
	--bp-color-primary-rgb: 255, 128, 129; // #ff8081;
	--bp-color-error-rgb: 229, 48, 49; // #e53031
	--bp-color-text-primary-rgb: 101, 101, 101; // #656565;
	--bp-color-text-secondary-rgb: 101, 101, 101; // #656565;

	bp-root,
	bp-header,
	main {
		padding: 0 !important;
	}

	bp-header {
		display: none;
	}

	bp-root {
		padding-top: 15px !important;
	}

	.card {
		border-radius: unset;
		background: transparent;
		box-shadow: none;
		padding: 0;

		.title {
			display: none;
		}

		form > .row {
			flex-flow: column;
		}

		.left {
			gap: 0.6rem;

			> .row {
				flex-flow: row !important;
				gap: 18px;
			}
		}

		.right {
			flex-flow: column;
			align-items: center;

			bp-page-note {
				order: 2;

				.reach--payment--banner {
					margin: 0;
				}
			}

			.secure-logos {
				order: 1;
				margin: 0 auto;
				flex-flow: wrap-reverse;
				margin-bottom: 20px;
				gap: 0.5rem;

				> img {
					margin: 0 !important;

					&.visa {
						width: 25px !important;
					}
				}
			}

			.credit-detail {
				order: 0;
				margin-bottom: 40px;
				margin-top: 13px !important;

				button {
					position: relative;
					right: unset;
					bottom: unset;
					top: unset;
					@include inset-inline-start(unset);
					z-index: 0;

					bp-dots-wave {
						display: none;
					}
				}

				&,
				button {
					width: 100%;
				}
			}
		}

		button.btn {
			text-transform: uppercase;
			height: 38px;
			padding: 0;
			box-shadow: none !important;
			font-size: 16px;
			font-weight: 500;

			&:not(:disabled):hover {
				background: $var-color-primary;
				color: white;
			}
		}

		&.status-card {
			.card-float-button-position {
				bottom: 0;
				justify-content: center;
				display: flex;
			}
		}
	}

	bp-amount {
		display: none;
	}

	input {
		font-size: 16px !important;
		line-height: 19px !important;
		font-weight: 400 !important;
		height: 19px !important;
		position: relative;
		top: -2px;

		@include placeholder {
			font-size: 16px !important;
			line-height: 19px !important;
			font-weight: 400 !important;
			color: #a1a1a1;
		}
	}

	bp-payment-card-brand-icon {
		top: -1px !important;
		@include inset-inline-end(-5px !important);
	}

	.mat-form-field-appearance-outline {
		padding-top: 1.2rem;

		.mat-form-field-outline {
			color: #c5c5c5;

			&-start,
			&-gap,
			&-end {
				border-top-color: currentColor !important;
			}
		}

		.mat-form-field-outline-thick {


			.mat-form-field-outline {
				&-start,
				&-gap,
				&-end {
					border-width: 1px;
				}
			}
		}

		.mat-form-field-flex {
			padding: 0 13px;
		}

		.mat-form-field-flex:hover .mat-form-field-outline-thick {
			color: unset !important;
		}


		&:not(.mat-form-field-invalid) .mat-form-field-label {
			color: unset !important;
		}

		&.mat-focused:not(.mat-form-field-invalid) {
			.mat-form-field-outline-thick {
				color: unset !important;
			}

		}

		&.mat-form-field-invalid {
			.mat-form-field-outline-thick {
				color: $var-color-error !important;
				background: #fff2f2;
			}
		}

		.mat-form-field-label-wrapper {
			font-size: 17px;
			top: -1.6em;
			@include inset-inline-start(-0.7em);

			.mat-form-field-label {
				transform: translateY(-1.69375em) scale(0.75);
				margin-top: -0.35em;
			}
		}

		.mat-form-field-subscript-wrapper {
			padding: 0;

			bp-validation-error {
				font-size: 12px;
			}
		}
	}
}