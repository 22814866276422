@mixin thick-scrollbar {
	@include _ff-thin-scrollbar();

	&::-webkit-scrollbar {
		height: 6px;
		width: 6px;
	}
	&::-webkit-scrollbar-track {
		-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	}
	&::-webkit-scrollbar-thumb {
		background-color: $color-mediumgray;
		outline: 1px solid $color-mediumgray;
	}
}

@mixin thin-scrollbar {
	@include _ff-thin-scrollbar();

	&::-webkit-scrollbar {
		height: 4px;
		width: 4px;
	}

	&::-webkit-scrollbar-track {
		-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
	}

	&::-webkit-scrollbar-thumb {
		background-color: $color-lightgray;
		outline: 1px solid $color-lightgray;
	}
}

@mixin thin-notrack-scrollbar {
	@include _ff-thin-scrollbar();

	&::-webkit-scrollbar {
		height: 2px;
		width: 2px;
	}

	&::-webkit-scrollbar-thumb {
		background-color: $color-alto;
		outline: 1px solid $color-alto;
		opacity: 0.2;
		border-radius: 1px;
	}
}

@mixin _ff-thin-scrollbar {
	// 1. Note if `scrollbar-width` is set, it takes precedence over any ::-webkit properties,
	//    so apply it only for FF.
	// 2. Note by default FF has thin scrollbar itself, but without setting `scrollbar-width` there are ugly
	//    arrows at the ends of scrollbar.
	@include isFirefox() {
		scrollbar-width: thin;
		scrollbar-color: rgba(0, 0, 0, 0.3) $color-lightgray;
	}
}
