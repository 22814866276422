html, // Must be targeted to apply scroll to root container in FF
body,
mat-sidenav.sidenav,
.mat-drawer-inner-container,
.mat-sidenav-content,
.cdk-global-overlay-wrapper,
.bp-scrollbar {
	@include thick-scrollbar();
}

.bp-thin-scrollbar,
.mat-autocomplete-panel,
.mat-select-panel,
.cdk-virtual-scroll-viewport {
	@include thin-scrollbar();
}
