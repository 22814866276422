// forms
form,
fieldset {
	margin: 0;
	padding: 0;
	border-style: none;
}

input:not([type]),
input[type='text'],
input[type='tel'],
input[type='email'],
input[type='search'],
input[type='password'],
input[type='url'],
input[type='date'],
textarea,
.form-control {
	&,
	&.mat-input-element {
		-webkit-appearance: none;
		-webkit-border-radius: 0;
		box-sizing: border-box;
		font-weight: 600;
		background: transparent;
		color: $white;
		height: 27px;
		margin: 0;
		padding: 0;
		width: 100%;
		display: block;
		border: solid $form-element-border-color;
		border-width: 0 0 1px 0;
		outline: none;
		box-shadow: none;
		text-overflow: ellipsis;

		@include notBrightThemeOrBlox {
			font-size: 12px;
			line-height: 20px;

			@include media('>=phone-xs') {
				font-size: 14px;
				line-height: 22px;
			}

			@include media('>=phone') {
				font-size: 18px;
				line-height: 24px;
			}
		}

		@include brightTheme {
			font-size: 17px;
			font-weight: 400;
			line-height: 0px;
		}

		&:-webkit-autofill {
			-webkit-background-clip: text;
			-webkit-text-fill-color: currentColor;
		}

		@include placeholder {
			color: #{'rgba(var(--bp-color-text-primary-rgb), 0.3)'};
		}
	}
}

.mat-select-trigger {
	@include notBrightThemeOrBlox {
		@extend .form-control;
		display: inline-flex;
		border: unset;
		color: inherit;
	}
}

select {
	-webkit-border-radius: 0;
}

textarea {
	resize: vertical;
	vertical-align: top;
}

button,
input[type='button'],
input[type='reset'],
input[type='file'],
input[type='submit'] {
	-webkit-appearance: none;
	-webkit-border-radius: 0;
	cursor: pointer;
}

input {
	caret-color: white;

	:root.transparent-theme &,
	:root.bright-theme &,
	:root.admin-theme &,
	:root.light-theme &,
	:root.equity & {
		caret-color: $var-color-primary;
	}

	:root:not(.blox) & {
		color: $var-color-text-primary !important;
	}

	:root.blox & {
		color: $var-color-text-primary;
	}

	:root.blox & {
		caret-color: currentColor;
	}
}
