$gray        : #c2c2c2;
$black       : #000;
$white       : #fff;
$white-rgb   : toRGB($white);
$toxic-blue  : #33ffff;
$text        : #3b3b3b;
$dark        : #363636;

$error       : #ff99cc;
$error-text  : lighten($error, 11%);
$warning-text: #ffbf6c;
$purple      : #9933cc;
$rose        : #ff6699;

$dark-theme-error: #ffd1e8;

$light-theme-gray          : #868686;
$light-theme-gray-rgb      : toRGB($light-theme-gray);
$light-theme-blue          : $color-primary;
$light-theme-blue-rgb      : toRGB($light-theme-blue);
$light-theme-error         : #ff486b;
$light-theme-error-rgb     : toRGB($light-theme-error);
$light-theme-success       : #39c653;
$light-theme-success-rgb   : toRGB($light-theme-success);
$light-theme-warning       : #ff9d1d;
$light-theme-warning-rgb   : toRGB($light-theme-warning);

$var-color-primary: #{'rgb(var(--bp-color-primary-rgb))'};
$var-color-error: #{'rgb(var(--bp-color-error-rgb))'};
$var-color-success: #{'rgb(var(--bp-color-success-rgb))'};
$var-color-warning: #{'rgb(var(--bp-color-warning-rgb))'};
$var-color-dark: #{'rgb(var(--bp-color-dark-rgb))'};
$var-color-text-primary: #{'rgb(var(--bp-color-text-primary-rgb))'};
$var-color-text-secondary: #{'rgb(var(--bp-color-text-secondary-rgb))'};
$var-input-underline-color: #{'rgb(var(--bp-input-underline-color-rgb))'};
$var-input-label-text-transform: #{'var(--bp-input-label-text-transform)'};

$card-gradient: linear-gradient(135deg,
$purple 0%,
#6666e6 12%,
#3399ff 31%,
#2c89f5 52%,
$color-primary 100%);

$success-gradient: linear-gradient(135deg,
#33cccc 0%,
#99b3cc 82%,
#ff6699 100%);

$warning-gradient: linear-gradient(135deg,
#ffcc33 1%,
#ff9980 82%,
#ff66cc 100%);

$error-gradient: linear-gradient(135deg,
#ff6699 0%,
#9999b3 82%,
#33cccc 100%);

$base-background-color: $white !default;
$font-size            : 16px !default;
$font-weight          : 400 !default;
$line-height          : 1.2 !default;
$font-family          : 'Montserrat', 'Arial', 'Helvetica Neue', 'Helvetica', sans-serif;
$border-radius        : 10px;
$border-color         : #e6e5e5;
// Liks
$base-link-color: $var-color-primary;

// Buttons
$btn-color     : $white !default;
$btn-background: $black !default;
$btn-padding   : 0.4em 0.7em !default;
$btn-font-size : inherit !default;

// Forms
$form-element-padding           : $btn-padding !default;
$form-element-border-color      : $white;
$form-element-focus-border-color: $black !default;
$placeholder-color              : rgba($white, 0.3);

// Headers
$var-h1-font-size: #{'var(--bp-h1-font-size)'};
$var-h2-font-size: #{'var(--bp-h2-font-size)'};
$var-h3-font-size: #{'var(--bp-h3-font-size)'};
$var-h4-font-size: #{'var(--bp-h4-font-size)'};
$var-h5-font-size: #{'var(--bp-h5-font-size)'};
$var-h6-font-size: #{'var(--bp-h6-font-size)'};

$var-h1-font-weight: #{'var(--bp-h1-font-weight)'};
$var-h2-font-weight: #{'var(--bp-h2-font-weight)'};
$var-h3-font-weight: #{'var(--bp-h3-font-weight)'};
$var-h4-font-weight: #{'var(--bp-h4-font-weight)'};
$var-h5-font-weight: #{'var(--bp-h5-font-weight)'};
$var-h6-font-weight: #{'var(--bp-h6-font-weight)'};

$var-font-size: #{'var(--bp-font-size)'};
$var-mat-body-1-font-size: #{'var(--bp-mat-body-1-font-size)'};
$var-mat-body-1-font-weight: #{'var(--bp-mat-body-1-font-weight)'};
$var-mat-body-1-line-height: #{'var(--bp-mat-body-1-line-height)'};
$var-mat-body-2-font-size: #{'var(--bp-mat-body-2-font-size)'};
$var-mat-body-2-font-weight: #{'var(--bp-mat-body-2-font-weight)'};
$var-mat-body-2-line-height: #{'var(--bp-mat-body-2-line-height)'};

$headings-font-family: inherit !default;
$headings-color      : inherit !default;

$mat-label-scale: scale(0.75);
$mat-infix-width: 180px;

$zindex-stub    : 100;
$zindex-button  : 101;
$crypto-img-size: 50px;

$padding-xs: 10px;
$padding-sm: 15px;
$padding-md: 20px;

$card-padding-xs: 15px;
$card-padding-sm: 20px;
$card-padding-md: 30px;

$btn-height: 50px;

$bright-theme-border-radius         : 6px;
$bright-theme-control-border-radius : 6px;
$bright-theme-mat-body-1-font-size  : 14px;
$bright-theme-mat-body-2-font-size  : 17px;
$bright-theme-mat-body-1-font-weight: 400;
$bright-theme-mat-body-2-font-weight: 400;
$bright-theme-general-gap           : 20px;
$bright-theme-layout-width          : 500px;
$bright-theme-row-height            : 45px;

$color-border: #DEDEDE;