@use '@angular/material' as mat;

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6,
.h {
	font-family: $headings-font-family;
	font-weight: 500;
	margin: 0 0 0.5em;
	color: $headings-color;
}

h1,
.h1 {
	font-size: $var-h1-font-size;
	font-weight: $var-h1-font-weight;
}

h2,
.h2 {
	font-size: $var-h2-font-size;
	font-weight: $var-h2-font-weight;
}

h3,
.h3 {
	font-weight: $var-h3-font-weight;
	font-size: $var-h3-font-size;
}

h4,
.h4 {
	font-size: $var-h4-font-size;
	font-weight: $var-h4-font-weight;
	text-transform: uppercase;
}

h5,
.h5 {
	font-weight: $var-h5-font-weight;
	font-size: $var-h5-font-size;
}

h6,
.h6 {
	font-weight: $var-h6-font-weight;
	font-size: $var-h6-font-size;
}

p {
	margin: 0 0 1em;
}

a {
	transition: all $transition-props;
	color: $base-link-color;
	text-decoration: none;
	outline: none;

	&:hover,
	&:focus {
		text-decoration: none;
	}
}

body,
button,
span,
p,
input,
textarea {
	letter-spacing: 0.02857em;
}

.uppercase {
	text-transform: uppercase;
}

hr {
	height: 1px;
	width: 100%;
	background: $border-color;
	margin: 1rem 0;
	border: none;
}

$core-typography-config: mat.define-legacy-typography-config(
	$font-family: #{'var(--bp-font-family)'},
	$body-2:
		mat.define-typography-level($var-mat-body-2-font-size, $var-mat-body-2-line-height, $var-mat-body-2-font-weight),
	$body-1:
		mat.define-typography-level($var-mat-body-1-font-size, $var-mat-body-1-line-height, $var-mat-body-1-font-weight),
);

@include mat.all-legacy-component-typographies($core-typography-config);

@include mat.legacy-core();

.mat-body-1 {
	margin: unset;
}
